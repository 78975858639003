const UserMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Repair Requests',
    is_heading: true,
    is_active: false,
    link: {
      name: 'user.repairs'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Pending',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.repairs.pending'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-screwdriver-wrench',
    active_icon: 'fad fa-screwdriver-wrench'
  },
  {
    title: 'Histories',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.repairs.histories'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-clock-rotate-left',
    active_icon: 'fad fa-clock-rotate-left'
  }
]

export default UserMenu
