const LogisticsOfficerMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Repair Requests',
    is_heading: true,
    is_active: false,
    link: {
      name: 'logistics-officer.repairs'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Pending',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.repairs.pending'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-wrench',
    active_icon: 'fad fa-wrench'
  },
  {
    title: 'Repair Approvals',
    is_heading: true,
    is_active: false,
    link: {
      name: 'logistics-officer.approvals'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Pending',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.approvals.pending'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-screwdriver-wrench',
    active_icon: 'fad fa-screwdriver-wrench'
  },
  {
    title: 'Histories',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.approvals.histories'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-clock-rotate-left',
    active_icon: 'fad fa-clock-rotate-left'
  },
  {
    title: 'Maintenances',
    is_heading: true,
    is_active: false,
    link: {
      name: 'logistics-officer.maintenances'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Vehicle Categories',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.maintenances.vehicle-categories'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-car-garage',
    active_icon: 'fad fa-car-garage'
  },
  {
    title: 'Vehicles',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.maintenances.vehicles'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-car',
    active_icon: 'fad fa-car'
  },
  {
    title: 'Suppliers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.maintenances.suppliers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-user-vneck-hair',
    active_icon: 'fad fa-user-vneck-hair'
  }
]

export default LogisticsOfficerMenu
