import MAdmin from './admin.menu'
import MLogisticsManager from './logistics-manager.menu'
import MLogisticsOfficer from './logistics-officer.menu'
import MPurchasing from './purchasing.menu'
import MSeniorMechanic from './senior-mechanic.menu'
import MUser from './user.menu'

export const getSidebarMenus = user => {
  switch (user.role) {
    case 'admin': {
      return MAdmin
    }
    case 'logistics-manager': {
      return MLogisticsManager
    }
    case 'logistics-officer': {
      return MLogisticsOfficer
    }
    case 'purchasing': {
      return MPurchasing
    }
    case 'senior-mechanic': {
      return MSeniorMechanic
    }
    case 'user': {
      return MUser
    }
    default: {
      return []
    }
  }
}
