const PurchasingMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'purchasing.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Repair Approvals',
    is_heading: true,
    is_active: false,
    link: {
      name: 'purchasing.approvals'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Pending',
    is_heading: false,
    is_active: false,
    link: {
      name: 'purchasing.approvals.pending'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-cart-circle-exclamation',
    active_icon: 'fad fa-cart-circle-exclamation'
  },
  {
    title: 'Histories',
    is_heading: false,
    is_active: false,
    link: {
      name: 'purchasing.approvals.histories'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-clock-rotate-left',
    active_icon: 'fad fa-clock-rotate-left'
  }
]

export default PurchasingMenu
